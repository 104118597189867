import React from 'react'
import {Helmet} from 'react-helmet'
import ModalImage from 'react-modal-image-responsive'
import Layout from '../components/layout'
// import Link from 'gatsby'
import './portfolio-workbench.css'
import PWFundImage from '../images/portfolio-workbench-fund.png'
import PWWireframe from '../images/PortfolioWorkbench/Exposures Layout Wireframe.png'
import PWExposures from '../images/PortfolioWorkbench/PW Exposures Browser.png'
import PWInput from '../images/PortfolioWorkbench/PW Input.png'
import PWImage from '../images/pw-fund.png'

export default function PortfolioWorkbench() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex" />
        <title>Leland Riordan - Portfolio - Portfolio Workbench</title>
      </Helmet>
      <div className="case-study-feature">
        <img src={PWFundImage} className="case-study-feature-image" alt='Citi Velocity'/>
      </div>
      <div className="main-content case-study-content">
        <div className="row row-max">
          <h1 className="case-study-title">Portfolio Workbench</h1>
          <p className="lead">
            Portfolio Workbench is Cambridge Associates' flagship portfolio management, analytics and trading platform.
            It is used by CA's investment teams to manage some of the world's most sophisticated investment portfolios for hedge funds, endowments, pensions and family office clients.
            From 2016 to 2019, I led UX design and front end development for the launch of the platform.
          </p>

          <h2>Introduction</h2>
          <p>
            In December of 2015, after the successful launch of the Client Dashboard, I was promoted to manage user experience and front end development across all applications at Cambridge Associates.
            The firm had just hired a new CEO and his top priority was to rapidly expand the firm's portfolio management business.
            My new marching orders were simple:
          </p>
          <blockquote>
            Design and develop a new flagship portfolio management, analytics, and trading platform to lead the company into the portfolio management business.
          </blockquote>
          <p>
            Operating like an internal startup, the firm assembled a hand-picked leadership team that included former fund managers, consulting partners and principal developers.
            I was responsible for managing the product design and front end development teams.
            We immeadiately got to work planning out a multi year strategy around the three primary milestones from the executives:
          </p>
          <ul>
            <li>Year 1 - Execute First Trade</li>
            <li>Year 2 - Launch Basic Analytics & Reporting</li>
            <li>Year 3 - Advanced Quant Analytics And Predictive Modeling</li>
          </ul>

          <h2>User Research</h2>
          <p>
            While the backend developers got to work building the core APIs, security and infrastructure, my design team got to work conducting user research and building out a design system.
            We conducted interviews, shadowed users, sent surveys, analyzed workflows, dug into analytics and studied competitors.
          </p>
          <p>
            It became clear that Cambridge's sophisticated multi-billion dollar portfolios were being managed by a loose combination of excel files and online tools.
            Analysts had to remake the same analytics and content for each client presentation or investment idea.
            Next, the traders had to manually key in the findings into external trading systems.
            Finally, investment directors and CIOs had to spend incredible amounts of aggregating all of this into client books for their monthly and quarterly client presentations.
            This whole process was tedious, expensive and could take upwards of weeks.
          </p>

          <figure className="column bg-gradient m-2">
            <figcaption>Input Validation</figcaption>
              <ModalImage
                small={PWInput}
                large={PWInput}
                className="case-study-image"
                hideDownload={true}
                hideZoom={true}
                alt="Input Validation"
              />
          </figure>

          <h2>Design Process</h2>
          <p>
            As this was the firm's highest priority, scrutiny was high and all eyes were on the Portfolio Workbench project.
            We took over a large conference room near the CTO's office and used it as our war room.
            I flew up to the firm's Boston HQ every week for a steering comittee meeting with the product team, CTO and Chief Risk Officer.
          </p>
          <p>
            On the design side, I led micro-design sprints of either 1 or 2 weeks in length so that we could make fast decisions.
            On Monday/Tuesday we would decide on a plan of attack for a given feature.
            Then on tuesday we would validate it with some user feedback sessions.
            And then on Wednesday/Thursday we would work on a low fidelity mockup or wireframe to walkthrough in our weekly demo with the steering committee.
          </p>
          <figure className="column bg-gradient m-2">
            <figcaption>Early Exposures Analytics Layout Wireframe</figcaption>
              <ModalImage
                small={PWWireframe}
                large={PWWireframe}
                className="case-study-image"
                hideDownload={true}
                hideZoom={true}
                alt="Early Client Dashboard Wireframe"
              />
          </figure>
          <p>
            Once we had the strategy nailed down, my design team and I would go higher fidelity with a prototype made in either code or inVision.
            I would then present this hi-fi version to the executives the following week for a final round of feedback and sign off.
            When we got the greenlight, I would work with my front-end developers to break the design into requirements and get it added to their next sprint.
          </p>
          <figure className="column bg-gradient m-2">
            <figcaption>Exposures Analytics Mockup Near Completion</figcaption>
              <ModalImage
                small={PWExposures}
                large={PWExposures}
                className="case-study-image"
                hideDownload={true}
                hideZoom={true}
                alt="Exposures Analytics Mockup Near Completion"
              />
          </figure>
          <p>
            This design process proved to be incredibly efficient and allowed us to rapidly design the platform from scratch that first year.
            As time progressed more clients were onboarded, the platform matured, the development team grew and the requirements got more complicated.
            We slowly transitioned to two and then eventually three week design sprints adapting as the product evolved but kept the same move fast ethos.
          </p>
          <p>

          </p>
        {/* </div>
        <div className="row row-max"> */}
          <h2>Outcome</h2>
          <p>
            The launch of Portfolio Workbench was a massive success and by the end of 2018 it was managing over $30 billion on a day to day basis.
            We hit our OKRs, executed billions of dollars in trades and steadily added functionality to automate more and more of the investment management process.
            The app became the center piece in Cambridge Associates transformation from an investment consultant into a portfolio management company.
            In the fall of 2019, I was hired by Citi to be the Head of UX for their flagship analytics, content and trading platform Citi Velocity.</p>
        </div>
      </div>
    </Layout>
  )
}
